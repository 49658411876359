@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Sans+Thai+Looped:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Sans+Thai+Looped:wght@100;200;300;400;500;600;700&family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body{
  font-family: "Sarabun", sans-serif;
}

input.form-control,select.form-control,textarea.form-control,div.css-13cymwt-control {
  background-color: #ebf3fc;
}
div.rpv-open__input-wrapper{
  display: none;
}

div.fieldInline label.form-label {
  float:left;
  padding-right: 10px;
}
div.fieldInline div.form-inline{
  float:left;
}

.ibm-plex-sans-thai-looped-thin {
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.ibm-plex-sans-thai-looped-extralight {
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.ibm-plex-sans-thai-looped-light {
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.ibm-plex-sans-thai-looped-regular {
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.ibm-plex-sans-thai-looped-medium {
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.ibm-plex-sans-thai-looped-semibold {
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.ibm-plex-sans-thai-looped-bold {
  font-family: "IBM Plex Sans Thai Looped", sans-serif;
  font-weight: 700;
  font-style: normal;
}

div.nav-item img.avatar  {
  max-height: 50px;
  max-width: 50px;
  border-radius:50%;

}